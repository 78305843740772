// breakpoint mixin
$breakpoints: (
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-wide":  1024px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);
@mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}


// sizing (tu mozna jakas sassowe 'map' wrzucic, ale musze jeszcze to doczytac, albo jakos te bootstrapowe variables podlaczyc

$sizer: 1rem;
$sizer-1: $sizer * 0.25;
$sizer-2: $sizer * 0.5;
$sizer-3: $sizer * 1;
$sizer-4: $sizer * 1.5;
$sizer-5: $sizer * 3;
$sizer-6: $sizer * 5;

$howitworks-img-length: 300px;
$howitworks-subimg-length: 65px;



// colors

$primary: #461d61;
$primary-dark: #6021b8;
$secondary: #fc5042;
$secondary-dark: #d13e31;
$light-grey: #f5f5f5;
$white: #fff;


// GENERAL

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
}

section {
    padding: 64px 0;
}

p {
    font-size: 1.25rem;
}

h3 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 2rem;
    margin-top: $sizer-5;
    margin-bottom: $sizer-4;
}

.btn {
    padding: 12px 24px;
}

.btn--rounded {
    border-radius: 10em;
}

.btn--narrow {
    padding: 8px 20px; 
}

.btn--filled {
    background-color: $primary;
    color: $light-grey;
    border-color: $primary; 
    &:hover {
        background-color: $primary-dark;
        border-color: $primary-dark;
        color: $white;
        cursor: pointer;
    }
}

.btn--filled--secondary {
    background-color: $secondary;
    color: $light-grey;
    border-color: $secondary; 
    &:hover {
        background-color: $secondary-dark;
        border-color: $secondary-dark;
        color: $white;
        cursor: pointer;
    }
}

.btn--outlined--secondary {
    color: $secondary;
    border-color: $secondary;
    &:hover {
        background-color: $secondary;
        color: $white;
        cursor: pointer;
    }
}

.text--center {
    text-align: center;
}

.section__heading {
    text-align: center;
    font-size: 2.75rem;
    padding-top: $sizer-5;
    padding-bottom: $sizer-6;
}

.shadow--primary {
    -webkit-box-shadow: 1px 4px 11px 0px rgba($primary, 0.75);
    -moz-box-shadow: 1px 4px 11px 0px rgba($primary, 0.75);
    box-shadow: 1px 4px 11px 0px rgba($primary, 0.75);
}

.shadow--secondary {
    -webkit-box-shadow: 1px 4px 11px 0px rgba($secondary, 0.75);
    -moz-box-shadow: 1px 4px 11px 0px rgba($secondary, 0.75);
    box-shadow: 1px 4px 11px 0px rgba($secondary, 0.75);
}


// HEADER SECTION

.navbar {
    padding-left: 0;
    padding-right: 0;
}

.navbar-dark .navbar-nav .nav-link {
    color: $light-grey;
    &:hover {
        color: $secondary;
    }
}

.navbar-brand {
    font-size: 1.5rem;
}

.nav-item {
    font-weight: 300;
    font-size: 1.25rem;
    color: $light-grey;
}

.header__content {
    height: 100vh;
    z-index: -1;
    background: linear-gradient( rgba($primary, 1), rgba($primary, .2) ), url(../images/equationly-background-2.jpg) center left / cover no-repeat;

    .header__text {
        color: $light-grey;
        margin-top: 30vh;
        @include mq('tablet') {
            margin-top: 15vh;
        }
    }

    .header__title {
        font-weight: 300;
        font-size: 2.75rem;
        @include mq('tablet') {
            font-size: 2rem;
            text-align: center;
        }
    }

    .header__subtitle {
        font-weight: 300;
        font-size: 2rem;
        @include mq('tablet') {
            font-size: 1.5rem;
            text-align: center;
        }
    }

    .header__btn {
        @include mq('tablet') {
            text-align: center;
        }
    }
}

.header__separator {
    width: 100%;
    height: 20vh;
    position: absolute;
    bottom: 0;
    fill: $white;
    stroke: transparent;
    @include mq('tablet-wide') {
        height: 15vh;
    }
    @include mq('phone-wide') {
        height: 10vh;
    }
}

.fill--primary {
    fill: $primary;
    opacity: 0.95;
}

.fill--secondary {
    fill: $secondary;
    opacity: 0.8;
}

// How it works section

.howitworks__img__wrapper {
    position: relative;
    width: $howitworks-img-length;
    margin: 0 auto;

}

.howitworks__img__cropper {
    width: $howitworks-img-length;
    height: $howitworks-img-length;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    border: 5px solid $primary;
}

.howitworks__img {
    width: 100%;
    height: auto;
}

.howitworks__subimg__wrapper {
    bottom: 0;
    right: 30px;
    position: absolute;
    width: $howitworks-subimg-length;
    height: $howitworks-subimg-length;
    border: 3px solid $secondary;
    border-radius: 50%;
    background-color: $white;
}

.howitworks__subimg {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    font-weight: bold;
    color: $secondary;
}


// SUBSCRIPTION SECTION

#subscription {
    background-color: rgb(243, 239, 239);
}

.subscription__form {
    justify-content: center;

    input {
        width: 300px;
        height: 50px;
    }
}

.form-control:focus {
    border-color: $primary;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($primary,.25)
}

.subscription__incentive {
    opacity: 0.75;
    font-size: 1rem;
}
